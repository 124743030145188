
import { LaunchRequest, TheiaCloud, RequestOptions } from '@eclipse-theiacloud/common';
import { defineComponent, handleError, toHandlers } from 'vue';
import Modal from './Modal.vue';

import {ensureEPIsSet} from '../../public/ping';

export default defineComponent({
  name: 'SessionLauncher',
  props: {
    serviceUrl: String,
    appDefinition: String,
    email: String,
    appId: String,
    useEphemeralStorage: Boolean,
    workspaceName: String,
    token: String,
    firstName: String
  },
  components: {
    Modal
  },  
  created() {    
    //window.addEventListener('beforeunload', this.resetLicence)  
  },
  data() {
    return {
      text: '',
      myuser: '', 
      showSpinner: false,
      licenceVisible: false
    };
  },

  mounted(){
    //this.myuser = sessionStorage.firstName;
  },

  watch: {
    email() {
      this.myuser = sessionStorage.firstName;
      console.log("in email watch",sessionStorage.licence,sessionStorage.attemptedLaunch);
      if (sessionStorage.licence == 1 && sessionStorage.attemptedLaunch == "false") {
        console.log("starting session via watch");
        this.text = 'Your Studio is being prepared...this usually takes less than a minute but it can take up to 3 minutes at peak times.';
        sessionStorage.attemptedLaunch = "true";
        this.startSession(0);
      }
    }
    
},
  methods: {
    //resetLicence() {},
    showModal() {
        this.licenceVisible = true;
      },
    acceptTerms() {
        this.licenceVisible = false;
        sessionStorage.licence = 1; //1 means licence has been accepted by the user
        //this will cause the page to be re-entered and invoke startSession on successive
        //revisits until service can be navigated too
        location.replace(window.location.href);
      },
    rejectTerms() {
        this.licenceVisible = false;
      },
    startSession(retries: number) {
      console.log('Calling to ' + this.serviceUrl);
      this.showSpinner = true;
      const launchRequest = this.useEphemeralStorage
        ? LaunchRequest.ephemeral(this.serviceUrl!, this.appId!, this.appDefinition!, undefined, this.email)
        : LaunchRequest.createWorkspace(
            this.serviceUrl!,
            this.appId!,
            this.appDefinition!,
            undefined,
            this.email,
            this.workspaceName
          );
      const options: RequestOptions = {
        accessToken: this.token,
        retries,
        timeout: 300000
      };
      
      TheiaCloud.launch(launchRequest, options)
        .then(url => {
          var ide = "https://"+url;
          console.log("launch returned");
          console.log(ide);
          this.showSpinner = false;

          var start = new Date().getTime();
          var end = start;
          while(end < start + 20000) {
              end = new Date().getTime();
          }
          location.href = ide;
          
          /*var prom1 = ensureEPIsSet(ide);
          prom1.then(
            function(value) {
                console.log("Finished provisioning a new POD at "+ide);
                console.log("cuurently in browser at location:"+location.href);
                console.log("we should go to "+ide);
                //ide will have something of the form
                //-->https://cloudstudiolockular.studioin.online/11d71c11-86ea-4b8a-9f7f-af6a8af42fdb/
                console.log("====================");
                var start = new Date().getTime();
                var end = start;
                while(end < start + 20000) {
                  end = new Date().getTime();
                }
                location.href = ide;

            },
            function(error) {console.log("REJECTED!!!!!!!!");console.log(error);}
          )*/
          })
        .catch(error => {
          this.text = error;
          this.showSpinner = false;
          console.error(error);
      });
    }
  }
});
