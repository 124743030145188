import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SessionLauncher = _resolveComponent("SessionLauncher")!

  return (_openBlock(), _createBlock(_component_SessionLauncher, {
    serviceUrl: _ctx.serviceUrl,
    appDefinition: _ctx.appDefinition,
    email: _ctx.email,
    appId: _ctx.appId,
    useEphemeralStorage: _ctx.useEphemeralStorage,
    workspaceName: _ctx.workspaceName,
    token: _ctx.token,
    firstName: _ctx.firstName
  }, null, 8, ["serviceUrl", "appDefinition", "email", "appId", "useEphemeralStorage", "workspaceName", "token", "firstName"]))
}